import React, { Component } from 'react';
import { intercomMessenger, MaintenanceMode, Exception } from 'componentlibrary';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Button, Spin, Icon } from 'antd';
import Config from '../../Config';
import ResponsiveLayout from '../../Containers/ResponsiveLayout';

import AuthModal from '../../Containers/AuthModal';
import { routes } from '../../routes';
import { loader } from '../../sass/modules/loader.module.scss';
import styles from './style.module.scss';
import { getCookieLangFromID, getCookieKeyForLocale } from '../../utils/CookieData';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      authAttempted: false
    };
  }

  // Make componentDidMount async so we can await getAppConfig
  async componentDidMount() {
    console.log('App componentDidMount called');
    const { wsConnect, configServiceFetchRequest } = this.props;
    console.log('Initial appConfig in componentDidMount:', this.props.appConfig);

    // Wait for the app config to load before proceeding
    await this.getAppConfig();

    const { appConfig } = this.props;

    if (appConfig && appConfig.maintenanceMode === false) {
      console.log('Calling handleAuth from componentDidMount after appConfig is ready');
      await this.handleAuth();
    } else {
      console.log('appConfig not ready or maintenance mode is on, not calling handleAuth yet');
    }

    configServiceFetchRequest(Config.configurationService.URL);
    wsConnect(Config.configurationService.WEBSOCKET);
  }

  componentDidUpdate(prevProps) {
    console.log('App componentDidUpdate called');
    const { auth, appConfig, i18n } = this.props;
    const { showError, authAttempted } = this.state;
    const { idToken } = auth;

    console.log('this.state :--- ', this.state);
    console.log('this.props :--- ', this.props);
    console.log('Updated appConfig:', appConfig);
    console.log('Auth state in componentDidUpdate:', auth);

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if ((prevProps.appConfig
      && prevProps.appConfig.maintenanceMode === true
      && appConfig.maintenanceMode === false)
      || (prevProps.appConfig === null && appConfig && appConfig.maintenanceMode === false)) {
        console.log('prevprops app config check .... ');
        this.handleAuth();
    }

    // **New Condition**: appConfig was null before and now available with maintenanceMode == false
    console.log('prevprops :--- ', prevProps)
    console.log('app config :--- ', appConfig)
    if (!authAttempted && prevProps.appConfig === null && appConfig && !appConfig.maintenanceMode && idToken === null) {
      console.log('App config loaded for the first time and maintenance mode is false.');
      this.setState({ authAttempted: true }, () => {
        this.handleAuth();
      });
    }

    // Handle language change based on cookie
    const username =
      idToken && idToken['cognito:username'] ? idToken['cognito:username'] : '';
    const cookieLanguage = getCookieLangFromID(getCookieKeyForLocale(username));

    if (
      prevProps.i18n &&
      cookieLanguage &&
      prevProps.i18n.language !== cookieLanguage
    ) {
      const currentLocale = cookieLanguage;
      i18n.changeLanguage(currentLocale);
    }

    if (prevProps
        && prevProps.i18n
        && cookieLanguage
        && prevProps.i18n.language !== cookieLanguage) {
      const currentLocale = cookieLanguage;
      i18n.changeLanguage(currentLocale);
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  componentWillUnmount() {
    clearInterval(this.appConfigInterval);
    const { wsDisconnect } = this.props;
    wsDisconnect();
  }

  async handleAuth() {
    console.log('handleAuth called');
    const { handleAuthSession, exchangeCodeForTokens } = this.props;

    const code = this.getSearchParam('code');
    const state = this.getSearchParam('state');

    console.log('Auth parameters: code=', code, ' state=', state);

    // We are not currently going through the authorization flow.
    // Call handleAuthSession to use an existing session or begin a new one.
    if (!code) {
      console.log('No auth code present. Calling handleAuthSession.');
      return handleAuthSession();
    }

    console.log('Auth code present. Exchanging for tokens.');

    // Auth code flow in progress. Invoke token exchange
    await exchangeCodeForTokens(code, state);

    console.log('Token exchange completed. Redirecting now.');
    return this.redirect();
  }

  handleAuthError() {
    this.setState({
      showError: true
    });
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return 'redirecting';
    }

    if (this.getSearchParam('code') !== null) {
      return 'authorizing';
    }

    return null;
  }

  get intercomPayload() {
    const { auth = {} } = this.props;

    if (!auth.idToken) {
      return {};
    }

    return {
      user_id: auth.idToken['custom:forwood_uuid'],
      user_hash: auth.idToken.hmac,
    };
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem('lastPath') || '/';
    return history.push(pathname);
  }

  render() {
    const { showError } = this.state;
    const {
      t, appConfig, auth = {}, authorize, showAuthConfirm
    } = this.props;
    const { idToken } = auth;
    if (!appConfig) {
      console.log('appConfig is null or undefined. Render returning null.');
      return null;
    }

    const { error, accessToken } = auth;

    if (this.loaderMessage) {
      return (
        <Spin
          className={loader}
          size="large"
          tip={t(this.loaderMessage)}
        >
          <div />
        </Spin>
      );
    }

    if (error && showError) {
      return (
        <Exception
          status={error.status}
          message={t(`errors:${error.status.toString()}`)}
        >
          <Button onClick={authorize}>
            {t('errors:tryAgain')}
          </Button>
        </Exception>
      );
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:systemDown')}
          description={t('maintenanceMode:pleaseLogout')}
        />
      );
    }

    if (!accessToken) {
      return (
        <AuthModal />
      );
    }

    const {
      permission: { teams, teamTargets }, i18n,
    } = this.props;

    const menuItem = [];
    const currentURL = window.origin;

    const username = idToken && idToken['cognito:username'] ? idToken['cognito:username'] : '';
    const cookieLanguage = getCookieLangFromID(getCookieKeyForLocale(username));

    if (teams && teams.access) {
      menuItem.push(
        {
          container: 'TeamsMicroFrontend',
          icon: <Icon type="team" className={styles.icon} />,
          title: t('teams'),
          path: '/teams'
        }
      );
    }

    if (teamTargets && teamTargets.access) {
      menuItem.push(
        {
          container: 'TargetsMicroFrontend',
          icon: <Icon type="safety" className={styles.icon} />,
          title: t('targets'),
          path: '/targets'
        }
      );
    }

    return (
      <>
        <ResponsiveLayout
          menuProps={menuItem}
          i18n={i18n}
          enabledSupportButtons={appConfig.enabledSupportButtons}
          currentUrl={currentURL}
        >
          {renderRoutes(routes)}
          <AuthModal />
        </ResponsiveLayout>
        {
          !showAuthConfirm && appConfig.intercomEnabled && intercomMessenger(
            Config.reactApp.HOSTNAME, cookieLanguage, this.intercomPayload, Config.ENVIRONMENT_NAME
          )
        }
      </>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null,
  showAuthConfirm: false
};

App.propTypes = {
  auth: PropTypes.object,
  handleAuthSession: PropTypes.func.isRequired,
  authorize: PropTypes.func.isRequired,
  exchangeCodeForTokens: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  permission: PropTypes.object.isRequired,
  showAuthConfirm: PropTypes.bool,
  wsConnect: PropTypes.func.isRequired,
  configServiceFetchRequest: PropTypes.func.isRequired,
  wsDisconnect: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
